import Home from './Home';
import HomeMobile from './HomeMobile';
import React from 'react';

const Homepage = ({ match }) => {
  const mql1 = window.matchMedia('(max-width: 700px)');

  let mobileView = mql1.matches;

  if (mobileView) {
    return <HomeMobile match={match} />;
  }

  return <Home match={match} />;
};

export default Homepage;
