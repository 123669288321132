import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import VideoPlayer from '../functional/VideoPlayer';
import $ from 'jquery';
import { useMousePosition } from '../functional/MousePosition';
import { useSwipeable } from 'react-swipeable';
import logo from '../img/logo.png';
import Particles from 'react-particles-js';

const GlobalStyle = createGlobalStyle`
  body, #root, html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #0A0A0A;
    color: white;
  }

  .line-overlay {
    background: url('https://hashed-web-files.s3.eu-central-1.wasabisys.com/imgs/lines.png');;
    background-size: cover;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    transform-origin: center;
    z-index: 2;
    position: absolute;
    transition: transform 1s ease-out;
    transform: scale(2,2);
  }

  .menu-button {
    font-weight: 600;
    background: none;
    border: 0;
    box-sizing: border-box;
    margin: 1em;
    padding: 1em 2em;
    
    color: white;
    font-size: inherit;
    font-weight: 700;

    position: relative;
    vertical-align: middle;

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .draw {
    transition: color 0.25s;

    &::before,
    &::after {
      border: 1px solid transparent;
      width: 0;
      height: 0;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      right: 0;
    }
    
    &:hover, &.active {
      color: #FF006A;
    }

    &:hover::before,
    &:hover::after, &.active::before, &.active::after {
      width: 100%;
      height: 100%;
    }

    &:hover::before, &.active::before {
      border-top-color: #FF006A;
      border-right-color: #FF006A;
      transition:
        width 0.25s ease-out,
        height 0.25s ease-out 0.25s;
    }

    &:hover::after, &.active::after {
      border-bottom-color: #FF006A;
      border-left-color: #FF006A;
      transition:
        border-color 0s ease-out 0.5s,
        width 0.25s ease-out 0.5s,
        height 0.25s ease-out 0.75s; 
    }
  }

  .shatter1 {
    text-transform: uppercase;
    font-weight:bold;
    font-size:6rem;
    position: relative;
    color: transparent;
    white-space: nowrap;
    
    &:before {
      content:"WE MAKE MODERN";
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      clip-path: inset(0 0 35px 0);
      transition:all 0.3s;
      white-space: nowrap;
    }
     
    &:after {
      content:"WE MAKE MODERN";
      position: absolute;
      color:white;
      top: 0;
      left: 0;
      clip-path: inset(39px 0 0 0);
      transition:all 0.5s;

      white-space: nowrap;
    }
    
    &:hover:after {
      top:2px;
      left: 10px;
      cursor:pointer;
      color:rgba(255,255,255,0.6);
      white-space: nowrap;
    }

    &:hover:before {
      top:-2px;
      cursor:pointer;
      color:rgba(255,255,255,0.6);
      white-space: nowrap;
    }
  }

  .block1, .block2, .block3, .block4 {
    will-change: transform, opacity;
    position: absolute;
  }

  .block1 {
    opacity: 1;
    transform: translateX(0);
  }

  .block2 {
    transform: translateX(100%);
    opacity: 0;
  }

  .block3 {
    transform: translateX(100%);
    opacity: 0;
  }

  .block4 {
    transform: translateX(100%);
    opacity: 0;
  }

  .moveCenter {
    transition: transform 1.5s ease-in-out, opacity 0.5s linear 1s;
    transform: translateX(0) !important;
    opacity: 1 !important;
    pointer-events: all !important;
  }

  .moveLeft {
    transition: transform 1.5s ease-in-out, opacity 1s linear;
    transform: translateX(-100%);
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .moveRight {
    transition: transform 1.5s ease-in-out, opacity 1s linear;
    transform: translateX(100%);
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .arrow {
    width: 2vw;
    height: auto;
  }

  #arrow1, #arrow2 {
    will-change: opacity;
    transition: opacity 1s ease-out;
    opacity: 1;
  }

  .hideArrow {
    opacity: 0 !important;
    pointer-events: none;
  }

  .showArrow {
    opacity: 1 !important;
    pointer-events: all;
  }

  .logo {

  }

  .navbar {

  }

  .navbar-wrapper {

  }

  .rightZero {
    right: 0;
  }

  @media (max-width: 1359px) {

    .rightZero {
      right: auto;
    }

    .navbar-tablet {
      margin-left: auto;
      margin-right: auto;
    }

    .logo-tablet {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5vw;

      img {
        width: 12vw !important;
      }
    }

    .navbar-wrapper-tablet {
      flex-direction: column;
    }

    .arrow-tablet {
      width: 10% !important;
    }

    .wrapper-tablet {
      width: 80% !important;
    }

    .hideOnTablet {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Navbar = styled.div``;
const Body = styled.div`
  position: relative;
  width: 100%;
`;
const Footer = styled.div``;

const Home = () => {
  const [page, setPage] = useState(0);

  const prev = () => {
    setPage(page - 1 < 0 ? 0 : page - 1);
  };

  const next = () => {
    setPage(page + 1 > 3 ? 3 : page + 1);
  };

  const removeClasses = () => {
    $('.block1').removeClass('moveLeft');
    $('.block2').removeClass('moveLeft');
    $('.block3').removeClass('moveLeft');
    $('.block4').removeClass('moveLeft');

    $('.block1').removeClass('moveCenter');
    $('.block2').removeClass('moveCenter');
    $('.block3').removeClass('moveCenter');
    $('.block4').removeClass('moveCenter');

    $('.block1').removeClass('moveRight');
    $('.block2').removeClass('moveRight');
    $('.block3').removeClass('moveRight');
    $('.block4').removeClass('moveRight');
  };

  useEffect(() => {
    switch (page) {
      case 0:
        $('#b1').addClass('active');
        $('#b2').removeClass('active');
        $('#b3').removeClass('active');
        $('#b4').removeClass('active');

        removeClasses();

        $('.block1').addClass('moveCenter');
        $('.block2').addClass('moveRight');
        $('.block3').addClass('moveRight');
        $('.block4').addClass('moveRight');

        $('#arrow2').removeClass('hideArrow');
        $('#arrow2').addClass('showArrow');

        $('#arrow1').removeClass('showArrow');
        $('#arrow1').addClass('hideArrow');
        break;
      case 1:
        $('#b2').addClass('active');
        $('#b1').removeClass('active');
        $('#b3').removeClass('active');
        $('#b4').removeClass('active');

        removeClasses();

        $('.block1').addClass('moveLeft');
        $('.block2').addClass('moveCenter');
        $('.block3').addClass('moveRight');
        $('.block4').addClass('moveRight');

        $('#arrow1').removeClass('hideArrow');
        $('#arrow2').removeClass('hideArrow');

        $('#arrow1').addClass('showArrow');
        $('#arrow2').addClass('showArrow');
        break;
      case 2:
        $('#b3').addClass('active');
        $('#b2').removeClass('active');
        $('#b1').removeClass('active');
        $('#b4').removeClass('active');

        removeClasses();

        $('.block1').addClass('moveLeft');
        $('.block2').addClass('moveLeft');
        $('.block3').addClass('moveCenter');
        $('.block4').addClass('moveRight');

        $('#arrow1').removeClass('hideArrow');
        $('#arrow2').removeClass('hideArrow');

        $('#arrow1').addClass('showArrow');
        $('#arrow2').addClass('showArrow');
        break;
      default:
        $('#b4').addClass('active');
        $('#b2').removeClass('active');
        $('#b3').removeClass('active');
        $('#b1').removeClass('active');

        removeClasses();

        $('.block1').addClass('moveLeft');
        $('.block2').addClass('moveLeft');
        $('.block3').addClass('moveLeft');
        $('.block4').addClass('moveCenter');

        $('#arrow1').addClass('showArrow');
        $('#arrow1').removeClass('hideArrow');

        $('#arrow2').removeClass('showArrow');
        $('#arrow2').addClass('hideArrow');
        break;
    }
  }, [page]);

  const pos = useMousePosition();

  const swipeConfig = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        next();
      } else {
        prev();
      }
    },
    ...swipeConfig,
  });

  return (
    <div
      className='fl-row'
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
    >
      <GlobalStyle />
      <VideoPlayer
        autoplay={true}
        controls={false}
        id='background-videos'
        customStyle={{
          position: 'absolute',
          minWidth: '100vw',
          minHeight: '100vh',
          opacity: '0.4',
          zIndex: '1',
          filter: 'blur(0.0rem) ',
          objectFit: 'cover',
        }}
        src='https://hashed-web-files.s3.eu-central-1.wasabisys.com/vids/background-vid-1.m3u8'
      />
      <Particles
        width='100vw'
        height='100vh'
        style={{ position: 'fixed' }}
        params={{
          particles: {
            number: {
              value: 20,
              density: {
                enable: true,
                value_area: 400,
              },
            },
            color: {
              value: '#ffffff',
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000',
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 2,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#ffffff',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'grab',
              },
              onclick: {
                enable: true,
                mode: 'push',
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 150,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />

      <a
        href='#!'
        id='arrow1'
        onClick={(e) => {
          e.preventDefault();
          prev();
        }}
        className='fl-row fl-full-center arrow-tablet'
        style={{
          position: 'relative',
          width: '15%',
          height: '100%',
          zIndex: '2',
        }}
      >
        <img
          src={require('../img/arrow.svg')}
          alt=''
          className='arrow'
          style={{ filter: 'invert(100%)' }}
        />
      </a>
      <Wrapper
        className='fl-column fl-align-center wrapper-tablet'
        style={{
          width: '70%',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '100%',
        }}
      >
        <Navbar
          style={{ flex: '0.5', zIndex: '2', width: '100%' }}
          className='fl-row fl-align-center navbar-wrapper navbar-wrapper-tablet'
        >
          <div className='logo-tablet logo'>
            <img
              alt=''
              src={logo}
              style={{
                width: '6vw',
                margin: '1.5vw',
                marginLeft: '0',
                filter: 'invert(100%)',
              }}
            />
          </div>

          <div
            className='navbar fl-row navbar-tablet'
            style={{ marginLeft: 'auto', minWidth: '500px' }}
          >
            <a
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                setPage(0);
              }}
              id='b1'
              className='fl-text-12 menu-button draw'
            >
              WEBSITES
            </a>
            <a
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                setPage(1);
              }}
              id='b2'
              className='fl-text-12 menu-button draw'
              style={{ marginLeft: '1vw', marginRight: '1vw' }}
            >
              WEB APPS
            </a>
            <a
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                setPage(2);
              }}
              id='b3'
              className='fl-text-12 menu-button draw'
              style={{ marginLeft: '1vw', marginRight: '1vw' }}
            >
              GAMES
            </a>
            <a
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                setPage(3);
              }}
              id='b4'
              className='fl-text-12 menu-button draw'
              style={{ marginLeft: '1vw' }}
            >
              CONTACT
            </a>
          </div>
        </Navbar>
        <Body
          style={{ flex: '6', zIndex: '2' }}
          className='fl-row fl-align-center'
        >
          <div
            style={{ flex: '2' }}
            className='fl-column fl-full-center'
            {...handlers}
          >
            <div className='block1 rightZero'>
              <div
                style={{
                  transition: 'transform 1s linear',
                  textAlign: 'right',
                  marginLeft: 'auto',
                  transform: `translate(${pos.x / (window.innerWidth / 5)}%,${
                    pos.y / (window.innerHeight / 10)
                  }%)`,
                }}
              >
                <div
                  className='fl-text-50 shatter1'
                  style={{ fontWeight: '900' }}
                >
                  WE MAKE MODERN
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  ADAPTIVE
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  AND BEAUTIFUL
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  WEBSITES
                </div>
              </div>
            </div>
            <div className='block2 rightZero'>
              <div
                style={{
                  transition: 'transform 1s linear',
                  textAlign: 'right',
                  marginLeft: 'auto',
                  transform: `translate(${pos.x / (window.innerWidth / 5)}%,${
                    pos.y / (window.innerHeight / 10)
                  }%)`,
                }}
              >
                <div className='fl-text-50' style={{ fontWeight: '900' }}>
                  WE DEVELOP
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  CUTTING-EDGE
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  RESPONSIVE
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  WEB APPS
                </div>
              </div>
            </div>

            <div className='block3 rightZero'>
              <div
                style={{
                  transition: 'transform 1s linear',
                  textAlign: 'right',
                  marginLeft: 'auto',
                  transform: `translate(${pos.x / (window.innerWidth / 5)}%,${
                    pos.y / (window.innerHeight / 10)
                  }%)`,
                }}
              >
                <div className='fl-text-50' style={{ fontWeight: '900' }}>
                  WE CREATE
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  POWERFUL
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  AND EASY TO USE
                </div>
                <div className='fl-text-27' style={{ fontWeight: '300' }}>
                  GAME ASSETS
                </div>
              </div>
            </div>
            <div className='block4 rightZero'>
              <div
                style={{
                  transition: 'transform 1s linear',
                  textAlign: 'right',
                  marginLeft: 'auto',
                  transform: `translate(${pos.x / (window.innerWidth / 5)}%,${
                    pos.y / (window.innerHeight / 10)
                  }%)`,
                }}
              >
                <div className='fl-text-50' style={{ fontWeight: '900' }}>
                  CONTACT INFO
                </div>
                <br />
                <div className='fl-text-21' style={{ fontWeight: '300' }}>
                  Games and Assets:
                  <br />
                  <a
                    style={{ fontWeight: '600' }}
                    href='mailto:byteveil.games@gmail.com'
                  >
                    byteveil.games@gmail.com
                  </a>
                </div>
                <br />
                <div className='fl-text-21' style={{ fontWeight: '300' }}>
                  Web Development:
                  <br />
                  <a
                    style={{ fontWeight: '600' }}
                    href='mailto:byteveil@gmail.com'
                  >
                    byteveil@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Body>
        <Footer
          style={{ flex: '0.5', zIndex: '2', width: '100%' }}
          className='fl-row fl-align-center'
        >
          <a
            href=''
            className='fl-text-12'
            style={{ marginLeft: 'auto', fontWeight: '900' }}
          >
            GITHUB
          </a>
          <a
            href=''
            className='fl-text-12'
            style={{ marginLeft: '2vw', fontWeight: '900' }}
          >
            COPYRIGHT - BYTEVEIL 2020
          </a>
        </Footer>
      </Wrapper>
      <a
        id='arrow2'
        href='#!'
        onClick={(e) => {
          e.preventDefault();
          next();
        }}
        className='fl-row fl-full-center arrow-tablet'
        style={{
          position: 'relative',
          width: '15%',
          height: '100%',
          zIndex: '2',
        }}
      >
        <img
          src={require('../img/arrow.svg')}
          alt=''
          className='arrow'
          style={{ filter: 'invert(100%)', transform: 'scaleX(-1)' }}
        />
      </a>
    </div>
  );
};

export default Home;
