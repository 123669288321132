import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  color: white;
  background: #191919;
`;

const Text = styled.p`
  text-align: left;
  max-width: 800px;
  margin: 0;
  padding: 10px;
`;

const MemoryPathTerms = () => {
  return (
    <Wrapper>
      <Text>
        <h1>Privacy Policy for Memory Path</h1>
        <h2>Last Updated: 07/27/2024</h2>

        <h3>Introduction</h3>
        <p>
          Welcome to Memory Path. By accessing or using our game, you agree to
          comply with and be bound by these Terms and Conditions. Please read
          them carefully.
        </p>

        <h3>Information Collection</h3>
        <p>
          We do not collect any personal information from our users. The game
          functions without the need for personal data.
        </p>

        <h3>Use of Information</h3>
        <p>
          Since we do not collect any personal information, there is no
          information to share, distribute, or use in any manner.
        </p>

        <h3>Third-Party Services and Advertisements</h3>
        <p>
          Our game includes advertisements from third-party services. These
          third-party services may collect information used to identify you. We
          advise you to review the privacy policies of these third-party
          services for more information on their data collection practices.
        </p>

        <h3>Security</h3>
        <p>
          We value your trust in using our game and strive to ensure a safe
          environment. However, as we do not collect any personal information,
          there is no data to secure.
        </p>

        <h3>Changes to These Terms and Conditions</h3>
        <p>
          We may update our Terms and Conditions from time to time. You are
          advised to review this page periodically for any changes. Any changes
          made to these Terms and Conditions will be in effect immediately after
          they are posted on this page.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions or suggestions about our Terms and
          Conditions, do not hesitate to contact us at{" "}
          <a href="mailto:byteveil@gmail.com">byteveil@gmail.com</a>.
        </p>
      </Text>
    </Wrapper>
  );
};

export default MemoryPathTerms;
