import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  color: white;
  background: #191919;
`;

const Text = styled.p`
  text-align: left;
  max-width: 800px;
  margin: 0;
  padding: 10px;
`;

const VATCalculatorTerms = () => {
  return (
    <Wrapper>
      <Text>
        <h1>Privacy Policy for VAT Calculator (Greece)</h1>
        <h2>Last Updated: 08/28/2023</h2>

        <h3>Introduction</h3>
        <p>
          Welcome to VAT Calculator (Greece). We recognize the importance of
          your privacy and are committed to protecting it.
        </p>

        <h3>Information We Collect</h3>
        <p>
          As the VAT Calculator (Greece) app is designed to perform simple tax
          calculations without the need for user authentication or data storage,
          we do not collect any personal information from our users.
        </p>

        <h3>Use of Information</h3>
        <p>
          Since we do not collect any personal information, there is no
          information to share, distribute, or use in any manner.
        </p>

        <h3>Third-Party Services</h3>
        <p>
          Our app does not use third-party services that may collect information
          used to identify you.
        </p>

        <h3>Security</h3>
        <p>
          We value your trust in providing your information, thus we strive to
          use commercially acceptable means of protecting it. However, as we do
          not collect any personal information, there is no data to secure.
        </p>

        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. Any changes
          made to this Privacy Policy will be in effect immediately after they
          are posted on this page.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{" "}
          <a href="mailto:byteveil@gmail.com">byteveil@gmail.com</a>.
        </p>
      </Text>
    </Wrapper>
  );
};

export default VATCalculatorTerms;
