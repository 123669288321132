import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "./App.css";

import HomeHub from "./pages/HomeHub";
import NotFound from "./pages/NotFound";
import VATCalculatorTerms from "./pages/VATCalculatorTerms";
import MemoryPathTerms from "./pages/MemoryPathTerms";

const App = () => {
  return (
    <Router>
      <Fragment>
        <Switch>
          <Route exact path="/" component={HomeHub} />
          <Route
            exact
            path="/vat-calculator/terms-and-conditions"
            component={VATCalculatorTerms}
          />
          <Route
            exact
            component={MemoryPathTerms}
            path="/memory-path/privacy-policy"
          />
          <Route component={NotFound} />
        </Switch>

        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="cookiePrompt"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "black", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Fragment>
    </Router>
  );
};

export default App;
