import React, { useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ autoplay, controls, src, id, customStyle }) => {
  useEffect(() => {
    var video = document.getElementById(id);
    var videoSrc = src;

    if (Hls.isSupported()) {
      var hls = new Hls({ autoStartLoad: false });
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.startLoad(1);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('canplay', function () {
        video.play();
      });
    }

    if (video.addEventListener) {
      video.addEventListener(
        'contextmenu',
        function (e) {
          e.preventDefault();
        },
        false
      );
    } else {
      video.attachEvent('oncontextmenu', function () {
        window.event.returnValue = false;
      });
    }

    //eslint-disable-next-line
  }, []);

  return (
    <video
      disablePictureInPicture
      controlsList='nodownload'
      id={id}
      loop
      muted={autoplay}
      autoPlay={autoplay}
      controls={controls}
      className='video-js'
      preload='auto'
      data-setup='{}'
      style={customStyle}
    >
      <p className='vjs-no-js'>
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href='https://videojs.com/html5-video-support/' target='_blank'>
          supports HTML5 video
        </a>
      </p>
    </video>
  );
};

export default VideoPlayer;
