import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import mobileBackground from '../img/mobileBackground.jpg';
import arrow from '../img/arrow.svg';
import $ from 'jquery';
import { useSwipeable } from 'react-swipeable';
import logo from '../img/logo.png';
import Particles from 'react-particles-js';

const GlobalStyle = createGlobalStyle`

  body, #root, html {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: black;
    color: white;
  }

  .block1 {
    position: relative;
    width: 100%;
    height: calc(100% - 13vw - 20vw);
  }

  .block1-overlay1 {
    background-image: url(${mobileBackground});
    background-size: cover;
    position: absolute;
    width: 120%;
    height: 120%;
    opacity: 0.3;
    will-change: transform;

    transition: transform 1.5s ease-in-out;
  }

  .block1-overlay2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('https://hashed-web-files.s3.eu-central-1.wasabisys.com/imgs/lines.png');;
    background-size: cover;
    opacity: 0.3;
  }

  .logo {
    width: 16vw;

    @media (max-width: 400px) {
      width: 20vw;
    }
  }

  .arrow {
    width: 6vw;
    filter: invert(100%);
  }

  .menu-button {
    font-weight: 600;
    background: none;
    border: 0;
    box-sizing: border-box;
    margin: 1em;
    padding: 1em 2em;

    font-size: 2vw;
    
    color: white;
    font-weight: 700;

    position: relative;
    vertical-align: middle;

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .draw {
    transition: color 0.25s;

    &::before,
    &::after {
      border: 1px solid transparent;
      width: 0;
      height: 0;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      right: 0;
    }
    
    &:hover, &.active {
      color: #FF006A;
    }

    &:hover::before,
    &:hover::after, &.active::before, &.active::after {
      width: 100%;
      height: 100%;
    }

    &:hover::before, &.active::before {
      border-top-color: #FF006A;
      border-right-color: #FF006A;
      transition:
        width 0.25s ease-out,
        height 0.25s ease-out 0.25s;
    }

    &:hover::after, &.active::after {
      border-bottom-color: #FF006A;
      border-left-color: #FF006A;
      transition:
        border-color 0s ease-out 0.5s,
        width 0.25s ease-out 0.5s,
        height 0.25s ease-out 0.75s; 
    }
  }

  .moveCenter {
    transition: transform 1.5s ease-in-out, opacity 0.5s linear 1s;
    transform: translateX(0) !important;
    opacity: 1 !important;
    pointer-events: all !important;
  }

  .moveLeft {
    transition: transform 1.5s ease-in-out, opacity 1s linear;
    transform: translateX(-100%) !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .moveRight {
    transition: transform 1.5s ease-in-out, opacity 1s linear;
    transform: translateX(100%) !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .block1-m, .block2-m, .block3-m, .block4-m {
    position: absolute !important;
    padding: 10vw;
  }

  .block2-m, .block3-m, .block4-m {
    opacity: 0;
    transform: translateX(100%);
    pointer-events: none;
  }

  .fl-text-15-local {
    font-size: 2.5vw !important;

    @media (max-width: 400px) {
      font-size: 3.75vw !important;
    }
  }

  .fl-text-21-local {
    font-size: 3vw !important;

    @media (max-width: 400px) {
      font-size: 5.25vw !important;
    }
  }

  .fl-text-27-local {
    font-size: 5vw !important;

    @media (max-width: 400px) {
      font-size: 6.75vw !important;
    }
  }

`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Navbar = styled.div`
  width: 100%;
  height: 10vw;
  background: white;
  padding-left: 5vw;
  padding-right: 5vw;

  @media (max-width: 400px) {
    height: 13vw;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 50px;
`;

const Menu = styled.div`
  width: 100%;
  height: 20vw;
  position: relative;
`;

const HomeMobile = () => {
  const [tiltX, setTiltX] = useState(0);
  const [tiltY, setTiltY] = useState(0);

  const [page, setPage] = useState(0);

  const swipeConfig = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        next();
      } else {
        prev();
      }
    },
    ...swipeConfig,
  });

  const prev = () => {
    setPage(page - 1 < 0 ? 0 : page - 1);
  };

  const next = () => {
    setPage(page + 1 > 3 ? 3 : page + 1);
  };

  const removeClasses = () => {
    $('.block1-m').removeClass('moveLeft');
    $('.block2-m').removeClass('moveLeft');
    $('.block3-m').removeClass('moveLeft');
    $('.block4-m').removeClass('moveLeft');

    $('.block1-m').removeClass('moveCenter');
    $('.block2-m').removeClass('moveCenter');
    $('.block3-m').removeClass('moveCenter');
    $('.block4-m').removeClass('moveCenter');

    $('.block1-m').removeClass('moveRight');
    $('.block2-m').removeClass('moveRight');
    $('.block3-m').removeClass('moveRight');
    $('.block4-m').removeClass('moveRight');
  };

  useEffect(() => {
    switch (page) {
      case 0:
        $('#b1').addClass('active');
        $('#b2').removeClass('active');
        $('#b3').removeClass('active');
        $('#b4').removeClass('active');

        removeClasses();

        $('.block1-m').addClass('moveCenter');
        $('.block2-m').addClass('moveRight');
        $('.block3-m').addClass('moveRight');
        $('.block4-m').addClass('moveRight');

        $('#arrow2').removeClass('hideArrow');
        $('#arrow2').addClass('showArrow');

        $('#arrow1').removeClass('showArrow');
        $('#arrow1').addClass('hideArrow');
        break;
      case 1:
        $('#b2').addClass('active');
        $('#b1').removeClass('active');
        $('#b3').removeClass('active');
        $('#b4').removeClass('active');

        removeClasses();

        $('.block1-m').addClass('moveLeft');
        $('.block2-m').addClass('moveCenter');
        $('.block3-m').addClass('moveRight');
        $('.block4-m').addClass('moveRight');

        $('#arrow1').removeClass('hideArrow');
        $('#arrow2').removeClass('hideArrow');

        $('#arrow1').addClass('showArrow');
        $('#arrow2').addClass('showArrow');
        break;
      case 2:
        $('#b3').addClass('active');
        $('#b2').removeClass('active');
        $('#b1').removeClass('active');
        $('#b4').removeClass('active');

        removeClasses();

        $('.block1-m').addClass('moveLeft');
        $('.block2-m').addClass('moveLeft');
        $('.block3-m').addClass('moveCenter');
        $('.block4-m').addClass('moveRight');

        $('#arrow1').removeClass('hideArrow');
        $('#arrow2').removeClass('hideArrow');

        $('#arrow1').addClass('showArrow');
        $('#arrow2').addClass('showArrow');
        break;
      default:
        $('#b4').addClass('active');
        $('#b2').removeClass('active');
        $('#b3').removeClass('active');
        $('#b1').removeClass('active');

        removeClasses();

        $('.block1-m').addClass('moveLeft');
        $('.block2-m').addClass('moveLeft');
        $('.block3-m').addClass('moveLeft');
        $('.block4-m').addClass('moveCenter');

        $('#arrow1').addClass('showArrow');
        $('#arrow1').removeClass('hideArrow');

        $('#arrow2').removeClass('showArrow');
        $('#arrow2').addClass('hideArrow');
        break;
    }
  }, [page]);

  useEffect(() => {
    function handleOrientation(event) {
      var x = event.beta; // In degree in the range [-180,180)
      var y = event.gamma; // In degree in the range [-90,90)

      setTiltX(x / 10);
      setTiltY(y / 10);
    }

    window.addEventListener('deviceorientation', handleOrientation);
    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper className='fl-column fl-align-center'>
      <GlobalStyle />
      <Navbar className='fl-row fl-full-center'>
        <img src={logo} className='logo' alt='' />
      </Navbar>
      <Menu className='fl-row fl-full-center'>
        <a
          id='b1'
          href='#!'
          className='menu-button draw'
          onClick={(e) => {
            e.preventDefault();
            setPage(0);
          }}
        >
          WEBSITES
        </a>
        <a
          id='b2'
          className='menu-button draw'
          href='#!'
          onClick={(e) => {
            e.preventDefault();
            setPage(1);
          }}
        >
          WEB APPS
        </a>
        <a
          id='b3'
          className='menu-button draw'
          href='#!'
          onClick={(e) => {
            e.preventDefault();
            setPage(2);
          }}
        >
          GAMES
        </a>
        <a
          id='b4'
          className='menu-button draw'
          href='#!'
          onClick={(e) => {
            e.preventDefault();
            setPage(3);
          }}
        >
          CONTACT
        </a>
      </Menu>
      <div className='fl-column block1' {...handlers}>
        <Particles
          width='100vw'
          height='100vh'
          style={{ position: 'fixed' }}
          params={{
            particles: {
              number: {
                value: 30,
                density: {
                  enable: true,
                  value_area: 400,
                },
              },
              color: {
                value: '#ffffff',
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#000000',
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: 'img/github.svg',
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 2,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: '#ffffff',
                opacity: 0.4,
                width: 1,
              },
              move: {
                enable: true,
                speed: 2,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: true,
                  mode: 'grab',
                },
                onclick: {
                  enable: true,
                  mode: 'push',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 150,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
        <div
          className='fl-column fl-justify-center'
          style={{ zIndex: '3', height: '100%' }}
        >
          <div
            className='fl-column fl-full-center'
            style={{ height: 'calc(100% - 13vw - 20vw)' }}
          >
            <div
              style={{
                width: '100%',
                marginTop: 'auto',
              }}
              className='fl-column fl-justfy-center block1-m'
            >
              <div className='fl-text-27-local' style={{ fontWeight: '900' }}>
                MODERN
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                ADAPTIVE
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                AND
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                BEAUTIFUL
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                WEBSITES
              </div>
              <div
                className='fl-text-15-local'
                style={{ fontWeight: '300', marginTop: '8vw' }}
              >
                Order your ideal website and let us embody your vision.
              </div>
            </div>

            <div
              style={{ width: '100%', marginTop: 'auto' }}
              className='fl-column fl-justfy-center block2-m'
            >
              <div className='fl-text-27-local' style={{ fontWeight: '900' }}>
                CUTTING-EDGE
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                RESPONSIVE
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                WEB
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                APPS
              </div>
              <div
                className='fl-text-15-local'
                style={{ fontWeight: '300', marginTop: '8vw' }}
              >
                Make any complex project a reality by utilizing the capabilities
                of a web application.
              </div>
            </div>

            <div
              style={{ width: '100%', marginTop: 'auto' }}
              className='fl-column fl-justfy-center block3-m'
            >
              <div className='fl-text-27-local' style={{ fontWeight: '900' }}>
                POWERFUL
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                AND
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                EASY-TO-USE
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                GAME
              </div>
              <div className='fl-text-27-local' style={{ fontWeight: '300' }}>
                ASSETS
              </div>
              <div
                className='fl-text-15-local'
                style={{ fontWeight: '300', marginTop: '8vw' }}
              >
                Use our asstes to expedite the development of projects.
              </div>
            </div>

            <div
              style={{ width: '100%', marginTop: 'auto' }}
              className='fl-column fl-justfy-center block4-m'
            >
              <div className='fl-text-27-local' style={{ fontWeight: '900' }}>
                CONTACT INFO
              </div>
              <div
                className='fl-text-21-local'
                style={{ fontWeight: '300', marginTop: '5vw' }}
              >
                Games and Assets:
                <br />
                <a
                  style={{ fontWeight: '600' }}
                  href='mailto:byteveil.games@gmail.com'
                >
                  byteveil.games@gmail.com
                </a>
              </div>
              <br />
              <div
                className='fl-text-21-local'
                style={{ fontWeight: '300', marginTop: '5vw' }}
              >
                Web Development:
                <br />
                <a
                  style={{ fontWeight: '600' }}
                  href='mailto:byteveil@gmail.com'
                >
                  byteveil@gmail.com
                </a>
              </div>
              <div
                className='fl-text-15-local'
                style={{ fontWeight: '300', marginTop: '8vw' }}
              >
                Have any questions? Feel free to contact us.
              </div>
            </div>
          </div>

          <div
            className='fl-row fl-full-center'
            style={{
              height: '20vw',
              width: '100%',
              marginTop: 'auto',
              display: 'none',
            }}
          >
            <a href='#!'>
              <img
                src={arrow}
                alt=''
                className='arrow'
                style={{ marginRight: '5vw' }}
              />
            </a>
            <a href='#!'>
              <img
                src={arrow}
                alt=''
                className='arrow'
                style={{ transform: 'scaleX(-1)', marginLeft: '5vw' }}
              />
            </a>
          </div>

          <Footer
            className='fl-row fl-full-center fl-text-12'
            style={{ zIndex: '3', padding: '10px', marginTop: 'auto ' }}
          >
            Copyright 2020 - ByteVeil
          </Footer>
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeMobile;
