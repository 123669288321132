import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  color: white;
  background: #191919;
`;

const Text1 = styled.strong`
  font-size: 25pt;
  margin-bottom: 10px;
`;

const Text2 = styled.span`
  font-size: 20pt;
  margin-bottom: 30px;
`;

const Text3 = styled.span`
  font-size: 12pt;
  color: #00a5ff;
`;

const NotFound = () => {
  return (
    <Wrapper>
      <Text1>Error 404</Text1>
      <Text2>Not Found</Text2>
      <Link
        to='/'
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Text3>Back To Homepage</Text3>
      </Link>
    </Wrapper>
  );
};

export default NotFound;
